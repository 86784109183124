import Modal from "@material-ui/core/Modal";
import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { useRouter } from "next/router";
import CHECKOUT_CONSTANTS, { CheckoutRoutes } from "../../src/action-constants/checkout-constants";
import { getShippingDetails } from "../../src/actions/checkout-actions";
import { getBlockedDates } from "../../src/actions/pdp-actions";
import callClevertapEvent from "../../src/analytics/clevertapUtility";
import {
  checkIsInternationalProduct,
  isCourierDelivery,
  isCourierProduct,
  isDigitalProduct,
  isPersonalizedProduct,
} from "../../src/utils/checkout";
import {
  dateToDMY,
  getDatesAround,
  sanitizeDateStringToDate,
  FnpPageType,
  getProductDeliveryType,
  getFnpPageType,
  isDateTodayOrTomorrow,
  processDeliveryDate,
} from "../../src/utils/common";
import { removeItemBasedOnKey } from "../../src/utils/search-utils";
import ModalDateTimePicker from "../desktop/pdp/modal-date-time-picker";
import slickStyles from "../desktop/pdp/style/calendar-slider-slick.module.scss";
import { cleverTapEventsConstants } from "../../src/analytics/clevertapConstants";
import { CAKE_TYPE } from "../../src/utils/pdp-constants";

const DeliveryCalender = dynamic(() => import("../desktop/pdp/calender"), { ssr: false });
const TimeSlot = dynamic(() => import("../desktop/pdp/time-slot"), { ssr: false });
const ShippingMethod = dynamic(() => import("../desktop/pdp/shipping-method"), { ssr: false });

/**
 * This Component renders the Domestic calender and shipping selections
 *
 * @param {object} root0 it includes object from parent component
 * @param {Function} root0.onSelect to handle selection.
 * @param {Function} root0.giftItem giftItem
 * @param {Function} root0.getPrice to handle price.
 * @param {Function} root0.onClose to close component.
 * @param {object} root0.cleverTapDeliveryData .
 * @param {object} root0.cleverTapCartCommonData track cleverTap analytics
 * @param {boolean} root0.triggeredFromCart to identify it's called from cart.
 * @returns {React.ReactElement} jsx for the calender and shipping selections
 */
function CheckoutDeliveryChangeDesktop({
  onSelect,
  giftItem,
  getPrice,
  onClose,
  cleverTapCartCommonData,
  cleverTapDeliveryData,
  triggeredFromCart,
}) {
  const sliderRef = useRef(null);
  const initialDate = null;
  const [open, setOpen] = useState(true);
  const [date, setDate] = useState(initialDate);
  const [dateRange, setDateRange] = useState("");
  const dispatch = useDispatch();
  const router = useRouter();
  const [showMultiOrderModal, setShowMultiOrderModal] = useState(false);
  const priceSurgeDetails = useSelector((state) => state.checkout?.priceSurgeDetails);
  const productPriceByDate = useSelector((state) => state.productDetail?.productPriceByDate);
  const [isSingleShippingMethod, setSingleShippingMethod] = useState(false);
  const [shippingMethod, setShippingMethod] = useState({});
  const [shippingMethodId, setShippingMethodId] = useState(null);
  const [userSelectedShippingMethodId, setUserSelectedShippingMethodId] = useState(null);
  const [userSelectedTimeSlotId, setUserSelectedTimeSlotId] = useState(null);
  const [isSameDateSelected, setIsSameDateSelected] = useState(false);
  const [isSameShippingSelected, setIsSameShippingSelected] = useState(false);

  const currency = useSelector((state) => state.appData?.selectedCurrency);
  const currencyCode = currency === "INR" ? "₹" : currency;
  const shippingDatesAndMethods = useSelector((state) => state.checkout?.shippingDatesAndMethods);
  const shippingDatesAndMethodsLoader = useSelector((state) => state.checkout?.shippingDatesAndMethodsLoader);
  const { shippingDatesAndMethods: shippingDatesAndMethodsFromProduct, blockedDates = [] } = useSelector(
    (state) => state.productDetail,
  );
  const { catalogId } = useSelector((state) => state.appConfigs);
  const { selectedType } = useSelector((state) => state.pdpContext);
  const { deliveryDates, shippingDetails, isShippingDetailsExists } = shippingDatesAndMethods;

  const isCourierDateRangeEnable = useSelector((state) => state.dateRangeSwitch?.isCourierDateRangeEnable);
  const isIncludeShippingDetails = useSelector((state) => state.productDetail?.isIncludeShippingDetails);

  const isProductOrAccountOrCartPage =
    getFnpPageType() === FnpPageType.PRODUCT ||
    getFnpPageType() === FnpPageType.MY_ACCOUNT ||
    getFnpPageType() === FnpPageType.CART_PREVIEW;

  const isDeliveryAddress = router?.query?.slug1 === CheckoutRoutes.DELIVERY_ADDRESS;
  const { nextAvailableDateDiff } = shippingDatesAndMethodsFromProduct.shippingEvents || {};
  const productDeliveryType = getProductDeliveryType(giftItem?.primaryProductCategoryId);
  const deliveryDateFormat = giftItem?.shippingDetails?.deliveryDate?.fullDeliveryDate;
  const cleverTapData = {
    product_name: giftItem.productName,
    pid: giftItem.productId,
    product_category: giftItem.primaryProductCategoryId,
    product_price: giftItem.price,
    is_variant_available: triggeredFromCart ? null : giftItem.isVariant,
    is_egg: selectedType?.toUpperCase() === CAKE_TYPE.WITH_EGG ?? false,
    delivery_type: productDeliveryType,
    pincode: giftItem.pincode,
    area: giftItem.deliveryLocation,
    system_pincode: giftItem.pincode,
    country: catalogId,
    city: giftItem.deliveryLocation,
    is_international: giftItem.isIntlCatalog,
    date_of_delivery: processDeliveryDate(deliveryDateFormat),
    delivery_shipping: giftItem.shippingDetails.shippingMethodName,
    delivery_shipping_fee: giftItem.shippingDetails.shippingPrice,
    delivery_slot: giftItem.shippingDetails.deliveryTimeSlot,
    next_available_date_difference: nextAvailableDateDiff,
    ...cleverTapCartCommonData,
  };

  useEffect(() => {
    removeItemBasedOnKey("selectedDates");
    removeItemBasedOnKey("selectedDate");
    const isInternational = checkIsInternationalProduct(giftItem?.mainproduct);
    const payload = {
      pincode: giftItem?.mainproduct?.pincode,
      productId: giftItem?.mainproduct?.productId,
      isInternational,
      countryGeoId: giftItem?.countryGeoId,
      isIncludeShippingDetails,
    };
    dispatch({ type: CHECKOUT_CONSTANTS.GET_SHIPPING_AND_DELIVERY_DATES, payload });
    getBlockedDates(giftItem?.mainproduct?.pincode, giftItem?.mainproduct?.productId, dispatch);
  }, [
    dispatch,
    giftItem.countryGeoId,
    giftItem.mainproduct,
    giftItem.mainproduct.pincode,
    giftItem.mainproduct.productId,
    isIncludeShippingDetails,
  ]);

  /**
   * This method is used to handle close delivery info screen
   *
   * @param {string} iconClick .
   */
  const handleClose = (iconClick) => {
    setOpen(false);
    const popupClosedCleverTapData = {
      ...cleverTapData,
      area: giftItem.deliveryLocation,
      pincode: giftItem.pincode,
      city: giftItem.deliveryLocation,
      system_pincode: giftItem.pincode,
      sku_id: giftItem?.mainproduct?.sku,
    };
    onClose(iconClick, popupClosedCleverTapData);
  };

  /**
   * This method handles next slide
   *
   */
  const gotoNext = () => {
    sliderRef.current.slickNext();
  };

  /**
   * This method is used to update the cartItem
   *
   * @param {object} selectedSlot slot object.
   */
  const updateCart = (selectedSlot) => {
    const { deliveryDate, slot, shipmentMethodId } = selectedSlot;
    const data = {
      itemIndex: giftItem?.cartItemIndex,
      shippingMethodId: shipmentMethodId,
      shippingPrice: slot?.shippingPrice,
      timeSlotId: slot?.timeSlotId,
    };

    const isEstimatedDateEnabled =
      giftItem?.estimatedDateEnabled && giftItem?.estimatedDateEnabled?.split(",")[0] === "Y";

    if (isEstimatedDateEnabled) {
      data.estimatedDateEnabled = "N";
    }

    if (giftItem?.isMultiOrderProduct) {
      data.deliveryDatesList = dateRange;
    } else {
      data.deliveryDate = dateToDMY(new Date(deliveryDate));
      const isDateRangeApplicable =
        isCourierDateRangeEnable &&
        isCourierDateRangeEnable === "Y" &&
        !isDigitalProduct(giftItem.mainproduct) &&
        isCourierDelivery(giftItem.mainproduct);
      if (isDateRangeApplicable && slot?.isShowDateRange) {
        const { startRangeDate, endRangeDate } = getDatesAround(data.deliveryDate, deliveryDates);
        data.leadTimeDeliveryDatesList = `${startRangeDate},${endRangeDate}`;
      } else if (isDateRangeApplicable) {
        data.leadTimeDeliveryDatesList = "";
      }
    }
    onSelect(data);
  };

  /**
   * This method is used to select time slot
   *
   * @param {object} selectedSlot selected time slot object.
   */
  function handleTimeSlotSelect(selectedSlot) {
    if (isProductOrAccountOrCartPage) {
      const slotSelectedData = {
        ...cleverTapData,
        date_of_delivery: date,
        delivery_shipping: selectedSlot?.shippingMethodName,
        delivery_shipping_fee: shippingMethod?.priceDetails?.price,
        delivery_slot: selectedSlot?.slot?.startTime
          ? `${selectedSlot?.slot?.startTime} - ${selectedSlot?.slot?.endTime}`
          : "",
        is_same_date_selected: isSameDateSelected,
        is_same_delivery_selected: isSameShippingSelected,
        is_same_slot_selected: userSelectedTimeSlotId === selectedSlot?.slot?.timeSlotId,
        sku_id: giftItem?.mainproduct?.sku,
      };
      callClevertapEvent(cleverTapEventsConstants.deliverySlotSelected, slotSelectedData);
    }
    if (isDeliveryAddress) {
      callClevertapEvent(cleverTapEventsConstants.deliverySlotSelected, {
        ...cleverTapDeliveryData,
        date_of_delivery: date,
        sku_id: giftItem?.mainproduct?.sku,
        delivery_shipping: selectedSlot?.shippingMethodName,
        delivery_slot: selectedSlot?.slot?.startTime
          ? `${selectedSlot?.slot?.startTime} - ${selectedSlot?.slot?.endTime} Hrs`
          : "",
        is_same_slot_selected: userSelectedTimeSlotId === selectedSlot?.slot?.timeSlotId,
        delivery_shipping_fee: selectedSlot?.slot?.shippingPrice,
      });
    }
    updateCart(selectedSlot);
    handleClose();
  }

  /**
   * This method is used to select shipping method
   *
   * @param {object} selectedShipping selected time slot object.
   */
  function handleShippingMethod(selectedShipping) {
    const shippingInfo = shippingDetails[selectedShipping?.shipmentMethodId];
    setShippingMethod(shippingInfo);
    setShippingMethodId(selectedShipping?.shipmentMethodId);
    if (isProductOrAccountOrCartPage) {
      const shippingCleverTapData = {
        ...cleverTapData,
        date_of_delivery: date,
        delivery_shipping: selectedShipping?.shippingMethodName,
        delivery_shipping_fee: shippingInfo?.priceDetails?.price,
        delivery_slot: undefined,
        is_same_date_selected: isSameDateSelected,
        is_same_delivery_selected: userSelectedShippingMethodId === selectedShipping?.shipmentMethodId,
        is_same_slot_selected: false,
        sku_id: giftItem?.mainproduct?.sku,
      };
      setIsSameShippingSelected(userSelectedShippingMethodId === selectedShipping?.shipmentMethodId);
      callClevertapEvent(cleverTapEventsConstants.deliveryShippingSelected, shippingCleverTapData);
    } else if (isDeliveryAddress) {
      callClevertapEvent(cleverTapEventsConstants.deliveryShippingSelected, {
        ...cleverTapDeliveryData,
        date_of_delivery: date,
        delivery_shipping: selectedShipping?.shippingMethodName,
        is_same_delivery_selected: userSelectedShippingMethodId === selectedShipping?.shipmentMethodId,
        delivery_shipping_fee: shippingInfo?.priceDetails?.price,
        sku_id: giftItem?.mainproduct?.sku,
      });
    }
    if (userSelectedShippingMethodId === selectedShipping?.shipmentMethodId) {
      setUserSelectedTimeSlotId(giftItem?.shippingDetails?.deliveryTimeSlotId);
    } else {
      setUserSelectedTimeSlotId(null);
    }
    gotoNext();
  }

  /**
   * This method is used to select date select
   *
   * @param {object} selectedDate date object.
   */
  async function handleDateSelect(selectedDate) {
    const userSelectedDate = sanitizeDateStringToDate(giftItem?.shippingDetails?.deliveryDate?.fullDeliveryDate);
    const alreadySelectedDate = new Date(userSelectedDate);
    const newSelectedDate = new Date(selectedDate);
    const { isDateToday, isDateTomorrow } = isDateTodayOrTomorrow(newSelectedDate);
    if (isProductOrAccountOrCartPage) {
      const dateSelectionCleverTapData = {
        ...cleverTapData,
        date_of_delivery: selectedDate,
        delivery_shipping: undefined,
        delivery_shipping_fee: undefined,
        delivery_slot: undefined,
        is_same_date_selected: alreadySelectedDate.getDate() === newSelectedDate.getDate(),
        is_same_delivery_selected: false,
        is_same_slot_selected: false,
        is_date_today: isDateToday,
        is_date_tomorrow: isDateTomorrow,
        sku_id: giftItem?.mainproduct?.sku,
      };
      setIsSameDateSelected(alreadySelectedDate.getDate() === newSelectedDate.getDate());
      callClevertapEvent(cleverTapEventsConstants.deliveryDateSelected, dateSelectionCleverTapData);
    } else if (isDeliveryAddress) {
      callClevertapEvent(cleverTapEventsConstants.deliveryDateSelected, {
        ...cleverTapDeliveryData,
        date_of_delivery: selectedDate,
        is_same_date_selected: alreadySelectedDate.getTime() === newSelectedDate.getTime(),
        is_date_today: isDateToday,
        is_date_tomorrow: isDateTomorrow,
        sku_id: giftItem?.mainproduct?.sku,
      });
    }

    userSelectedDate?.setHours(0, 0, 0, 0);
    selectedDate?.setHours(0, 0, 0, 0);
    if (selectedDate?.getTime() === userSelectedDate?.getTime()) {
      setUserSelectedShippingMethodId(giftItem?.shippingDetails?.shippingMethodId);
    } else {
      setUserSelectedShippingMethodId(null);
    }
    setSingleShippingMethod(false);
    setDate(selectedDate);
    const { productId, pincode } = giftItem?.mainproduct;
    const requestParams = {
      productId,
      pinCode: pincode,
      deliveryDate: dateToDMY(selectedDate, "/"),
      countryGeoId: giftItem?.countryGeoId,
    };
    const isInternational = checkIsInternationalProduct(giftItem?.mainproduct);
    dispatch({ type: CHECKOUT_CONSTANTS.SHIPPING_DETAILS_FETCH_START });
    const shippingResponse = await getShippingDetails(requestParams, isInternational);
    const { shippingDetails: details } = shippingResponse?.data;

    if (shippingResponse?.data?.isShippingDetailsExists) {
      const payload = shippingResponse?.data;
      dispatch({ type: CHECKOUT_CONSTANTS.SET_SHIPPING_DETAILS, payload });
      const shippingKeys = Object.keys(details);
      // if there is only one shipping method then select and close
      if (shippingKeys.length === 1) {
        const [shipping] = shippingKeys;
        details[shipping].shipmentMethodId = shipping;
        const shippingInfo = details[shipping];
        if (shippingInfo.shippingMethodName === "Courier" && shippingInfo.timeSlots.length === 1) {
          const [slot] = shippingInfo.timeSlots;
          handleTimeSlotSelect({
            shippingMethodName: shippingInfo.shippingMethodName,
            shipmentMethodId: shipping,
            slot,
            deliveryDate: selectedDate,
          });
          handleClose();
        } else {
          const [shippingKey] = shippingKeys;
          sliderRef.current.slickGoTo(2);
          setSingleShippingMethod(true);
          handleShippingMethod({
            shipmentMethodId: shippingKey,
            shippingMethodName: details[shippingKey].shippingMethodName,
          });
        }
      } else {
        gotoNext();
      }
    }
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    swipe: false,
    touchMove: false,
    className: slickStyles["slick-wrapper-calendar"],
  };

  /**
   * This method handles the visibility of
   * the edit date and next options pop up
   *
   * @param {number} index current index.
   */
  const setPopupVisible = (index = sliderRef.current.innerSlider.state.currentSlide - 1) => {
    if (!index && dateRange) {
      setTimeout(() => setShowMultiOrderModal(true), 500);
    }
  };

  /**
   * This method handles previous slide
   *
   */
  const gotoPrev = () => {
    setPopupVisible();
    sliderRef.current.slickPrev();
  };

  /**
   * This method handles go to calender screen
   *
   */
  const gotoCalender = () => {
    setPopupVisible(0);
    sliderRef.current.slickGoTo(0);
  };

  /**
   * This method is used to select time slot
   *
   * @param {object} slot selected time slot object.
   */
  function handleTimeSlot(slot) {
    handleTimeSlotSelect({
      shippingMethodName: shippingMethod.shippingMethodName,
      shipmentMethodId: shippingMethodId,
      slot,
      deliveryDate: date,
      dateRange,
    });
    setOpen(false);
  }

  /**
   * This functional component represents the domestic shipping slider .
   *
   * @returns {React.Fragment} main jsx for the domestic pdp.
   */
  const domesticProductDelivery = () => {
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalDateTimePicker showLoader={shippingDatesAndMethodsLoader} onClose={() => handleClose("cross-icon")}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Slider {...settings} ref={sliderRef}>
            <div>
              <DeliveryCalender
                isCheckout
                onSelectRange={setDateRange}
                onSelect={handleDateSelect}
                deliveryDates={deliveryDates}
                isCourierProduct={isCourierProduct(giftItem?.mainproduct)}
                isPersonalizedProduct={isPersonalizedProduct(giftItem?.mainproduct)}
                isCourierDelivery={isCourierDelivery(giftItem?.mainproduct)}
                productDetail={{
                  product: {
                    ...giftItem?.mainproduct,
                    isMultiOrderProduct: giftItem?.isMultiOrderProduct,
                    multiOrderProductDetails: giftItem?.multiOrderProductDetails,
                  },
                  priceSurgeDetails,
                  productPriceByDate,
                  shippingDatesAndMethods,
                }}
                pinCode={giftItem?.mainproduct?.pincode}
                itemDesiredDeliveryDate={giftItem?.shippingDetails?.deliveryDate?.fullDeliveryDate}
                itemDesiredDeliveryDatesList={
                  giftItem?.isMultiOrderProduct ? giftItem?.shippingDetails?.selectedDatesList?.toString() : ""
                }
                selectedVariant={() => {}}
                checkIsInternationalProduct={checkIsInternationalProduct(giftItem?.mainproduct)}
                getPrice={getPrice}
                currencyCode={currencyCode}
                showMultiOrderModal={showMultiOrderModal}
                setShowMultiOrderModal={setShowMultiOrderModal}
                isCourierDateRangeEnable={
                  isCourierDateRangeEnable === "Y" && giftItem?.shippingDetails?.leadTimeDeliveryDatesList?.length > 0
                }
                blockedDates={blockedDates}
              />
            </div>

            {!isSingleShippingMethod && (
              <div>
                <ShippingMethod
                  gotoPrev={gotoPrev}
                  onSelect={handleShippingMethod}
                  shippingDetails={shippingDetails}
                  isShippingDetailsExists={isShippingDetailsExists}
                  checkIsInternationalProduct={checkIsInternationalProduct(giftItem?.mainproduct)}
                  getPrice={getPrice}
                  currencyCode={currencyCode}
                  selectedShippingMethodId={userSelectedShippingMethodId}
                />
              </div>
            )}
            <div>
              <TimeSlot
                gotoPrev={gotoPrev}
                shippingMethod={shippingDetails?.[shippingMethodId] || shippingMethod}
                shippingMethodId={shippingMethodId}
                shippingDetails={shippingDetails}
                isShippingDetailsExists={isShippingDetailsExists}
                selectedDeliveryDate={date}
                gotoCalender={gotoCalender}
                onSelect={handleTimeSlot}
                checkIsInternationalProduct={checkIsInternationalProduct(giftItem?.mainproduct)}
                getPrice={getPrice}
                currencyCode={currencyCode}
                selectedTimeSlotId={userSelectedTimeSlotId}
              />
            </div>
          </Slider>
        </ModalDateTimePicker>
      </Modal>
    );
  };
  return <>{domesticProductDelivery()}</>;
}

CheckoutDeliveryChangeDesktop.propTypes = {
  onSelect: PropTypes.func.isRequired,
  getPrice: PropTypes.func.isRequired,
  giftItem: PropTypes.objectOf(PropTypes.any).isRequired,
  onClose: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  cleverTapDeliveryData: PropTypes.objectOf(PropTypes.any).isRequired,
  cleverTapCartCommonData: PropTypes.objectOf(PropTypes.any).isRequired,
  triggeredFromCart: PropTypes.bool,
};

CheckoutDeliveryChangeDesktop.defaultProps = {
  triggeredFromCart: false,
};

export default CheckoutDeliveryChangeDesktop;
