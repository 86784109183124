import React, { useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { parseCookies } from "nookies";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "@material-ui/core";
import errorLogger from "../../app_configs/logger-service";
import { CDP_ERORR_MSG } from "../../src/constants/common/errorConstants";

import MobileHeader from "./mobile/mobile-header";

import APP_CONSTANTS from "../../src/action-constants/app-actions";
import {
  checkCartAndLoginCookie,
  getPageName,
  getRoutePageName,
  setIsMicroSite,
  RoutePageConstants,
} from "../../src/utils/common";
import MobileFooter from "./mobile/mobile-footer";
import CommonPageActionsConstants from "../../src/action-constants/common-page-actions-constants";
import callClevertapEvent from "../../src/analytics/clevertapUtility";
import { cleverTapEvents, cleverTapEventsConstants } from "../../src/analytics/clevertapConstants";

const Error = dynamic(() => import("../../pages/_error"), {
  ssr: false,
});

const LocationLockStrip = dynamic(() => import("../mobile/location-lock/location-lock-strip"), {
  loading: () => <div style={{ height: "40px" }} />,
  ssr: false,
});

/**
 * Component for layout of mobile
 *
 * @param {object} root0  props passed to the component .
 * @param {React.ReactElement} root0.children represents children passed to MobileLayout
 * @param {string} root0.errorPageName represents error page name
 * @param {number} root0.statusCode represents error status code
 * @returns {React.ReactElement} main JSX for the MobileLayout component
 */
function MobileLayout({ children, errorPageName, statusCode }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const { lastSelectedPincode, faul, lastSelectedAddress } = parseCookies();
  const pageName = errorPageName || getRoutePageName(router.pathname);

  const { catalogId, rootGeoId, configData } = useSelector((state) => state.appConfigs);
  const contactDetails = configData?.mobile?.contactNo;
  const showBottomSticky = pageName === RoutePageConstants.PLP_PAGE;

  const slugLength = Object.keys(router.query).filter((item) => item.match(/slug/)).length;
  const isInternationalHomepage = catalogId !== "india" && router.query.slug2 === "gifts" && slugLength === 2;
  const slug2 = router.query.slug1;

  const showLocationLockStrip =
    router.query.slug1 !== "search" &&
    catalogId === "india" &&
    [RoutePageConstants.PLP_PAGE, RoutePageConstants.HOME].includes(pageName);

  const showErrorContent = pageName === RoutePageConstants.SERVER_ERROR;
  const showMobileFooter =
    [
      RoutePageConstants.PLP_PAGE,
      RoutePageConstants.MICROSITE,
      RoutePageConstants.HOME,
      RoutePageConstants.SERVER_ERROR,
      RoutePageConstants.TESTIMONIAL,
      RoutePageConstants.NOT_FOUND,
      RoutePageConstants.EXPERIENCES_PAGE,
    ].includes(pageName) || ["my-tickets", "update-ticket"].includes(slug2);
  const countTime = useRef(0);

  /**
   * This method calls the checkLoginCartInfo method to fetch the login flag and the cart information
   * of the user. Further, it takes the response and dispatch the data to the store
   */
  const getLoginCartInfo = useCallback(async () => {
    if (checkCartAndLoginCookie()) {
      dispatch({
        type: APP_CONSTANTS.INITIATE_FUS,
      });
    } else {
      dispatch({
        type: APP_CONSTANTS.LOGIN_CHECKED,
      });
    }
  }, [dispatch]);
  useEffect(() => {
    dispatch({ type: CommonPageActionsConstants.GET_CALL_CHAT_TIME });
  }, [dispatch]);

  useEffect(() => {
    setIsMicroSite(isInternationalHomepage);
  }, [isInternationalHomepage]);

  useEffect(() => {
    getLoginCartInfo();
    router.events.on("routeChangeStart", () => {
      getLoginCartInfo();
    });
    return () => router.events.off("routeChangeStart");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /**
     * This method invokes initial method of cdp events mapper and all dom bindings.
     */
    const initializeCdp = async () => {
      const module = await import("../../src/cdp/cdp-events-mapper");
      module.initCdpEvents(getPageName(router.pathname), "pageLoad", "");
      module.initDomBindings();
    };
    /**
     * This method waits for cdp object to be present on the dom to call
     * the callback function to initialize cdp events.
     */
    function waitForCdpObject() {
      countTime.current += 1;
      if (window.cdp) {
        initializeCdp();
      } else if (countTime.current <= 2) {
        setTimeout(() => {
          waitForCdpObject();
        }, 500);
      } else {
        errorLogger(CDP_ERORR_MSG);
      }
    }
    waitForCdpObject();
  }, [router.query.slug1, router.pathname]);

  useEffect(() => {
    if (window.localStorage.getItem("socialLogin") && lastSelectedPincode && lastSelectedAddress && faul) {
      dispatch({
        type: APP_CONSTANTS.SET_LOGGED_IN_PINCODE,
        payload: {
          pincode: lastSelectedPincode,
          email: faul,
          customerAddress: lastSelectedAddress,
        },
      });

      window.localStorage.removeItem("socialLogin");
    }
    if (window.sessionStorage.getItem("ctapSocialLogin")) {
      const loginAttributes = {
        Site: {
          Identity: faul,
          Email: faul,
        },
      };
      const loginSuccessEvent = {
        loginSuccess: "Login Success",
      };
      const publishCleverTapData = {
        profile: { identity: faul, platform: "web" },
      };
      dispatch({ type: CommonPageActionsConstants.POST_DATA_IN_CLEVER_TAP, payload: publishCleverTapData });
      callClevertapEvent(cleverTapEvents.userLogin, loginAttributes, cleverTapEvents.userLogin);
      callClevertapEvent(cleverTapEventsConstants.loginSuccess, loginSuccessEvent);
      window.sessionStorage.removeItem("ctapSocialLogin");
    }
    // not required re-rendering only it executes on first render of page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="mobile-main-container">
      <MobileHeader
        pageName={pageName}
        country={rootGeoId}
        contactDetails={contactDetails}
        isInternationalHomepage={isInternationalHomepage}
      />
      {showLocationLockStrip && <LocationLockStrip />}
      {showErrorContent ? <Error statusCode={statusCode} pageName={pageName} /> : children}
      {showMobileFooter && <MobileFooter showBottomSticky={showBottomSticky} />}
    </Container>
  );
}

MobileLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  errorPageName: PropTypes.string,
  statusCode: PropTypes.number,
};

MobileLayout.defaultProps = {
  children: React.createElement("span"),
  errorPageName: "",
  statusCode: 200,
};

export default MobileLayout;
