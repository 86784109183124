export const cleverTapEvents = {
  common: "Common",
  userLogin: "UserLogin",
  charged: "Charged",
  PO: "PO",
  DA: "DA",
  THANK_YOU: "THANKYOU",
  PNC: "PNC",
  LOGIN: "LOGIN",
};

export const cleverTapEventsConstants = {
  pageViewed: "Page Viewed",
  paymentPageViewed: "PO Page Viewed",
  deliveryPageViewed: "DA Page Viewed",
  loginPageViewed: "Login Page Viewed",
  pdpPageViewed: "PDP Page Viewed",
  thankuPageViewed: "ThankYou Page Viewed",
  pncPageViewed: "PNC Page Viewed",
  proceedToPay: "Proceed To Pay Clicked",
  savedAddress: "Saved Address Selected",
  newAddress: "New Address Added",
  addonRemoved: "Addon Removed",
  addonAdded: "Addon Added",
  productRemoved: "Product Removed",
  couponClicked: "Coupon Clicked",
  couponApplied: "Coupon Applied",
  fnpCashApplied: "Fnp Cash Applied",
  freeMsjCardClicked: "Free Message Card Clicked",
  freeMsjCardCancelled: "Free Message Card Cancelled",
  freeMsjCardAdded: "Free Message Card Added",
  payClicked: "Pay Now Clicked",
  variantSelected: "Variant Selected",
  locationClicked: "Location Clicked",
  locationRemoved: "Location Removed",
  locationPincodeSelected: "Location Pincode Selected",
  locationDontKnowPincodeClicked: "Location Dont Know Pincode Clicked",
  locationAreaSelected: "Location Area Selected",
  locationCitySelected: "Location City Selected",
  locationSaved: "Location Saved",
  wantSameDayClicked: "Want Same Day Clicked",
  deliveryPopupViewed: "Delivery Popup Viewed",
  deliveryDateSelected: "Delivery Date Selected",
  deliveryShippingSelected: "Delivery Shipping Selected",
  deliverySlotSelected: "Delivery Slot Selected",
  deliveryPopupClosed: "Delivery Popup Closed",
  messageOnCakeEntered: "Message On Cake Entered",
  nameToPrintEntered: "Name To Print Entered",
  deliveryTypeSelected: "Delivery Type Selected",
  uploadPhotoDone: "Upload Photo Done",
  addToCartClicked: "Add To Cart Clicked",
  buyNowClicked: "Buy Now Clicked",
  addonViewed: "Addon Viewed",
  addonNotViewed: "Addon Popup Not Viewed",
  addonClosed: "Addon Closed",
  addonContinueClicked: "Addon Continue Clicked",
  widgetClicked: "Widget Clicked",
  cartViewed: "Cart Page Viewed",
  proceedToCheckoutClicked: "Proceed To Checkout Clicked",
  googleLoginSelected: "Google Login Selected",
  emailEntered: "Email Entered",
  otpClicked: "Login with OTP Clicked",
  registrationViewed: "Registration Page Viewed",
  regisDetEnter: "Registration Detail Entered",
  otpVerfViewed: "OTP Verification Viewed",
  otpVerfDone: "OTP Verification Done",
  resendOtpSelected: "Resend OTP Selected",
  loginSuccess: "Login Success",
  validationViewed: "Validation Viewed",
  retryPaymentClicked: "Retry Payment Clicked",
  headerMenuClicked: "Header Clicked",
  bottomNavigationNavIconClicked: "Bottom Navigation Nav Icon Clicked",
  singleImgUpload: "single image upload",
  occasionReminderSaved: "Occasion Reminder Saved",
  cartEmpty: "Cart Empty",
  sessionStart: "Custom Session Start",
};

export const cleverTapCommonWords = {
  PDP: "PDP",
  CART: "CART",
  registration: "REGISTRATION",
  locationField: "Location Field",
  nameToPrint: "Name to Print",
  personalizedImage: "Personalized Image",
  emailId: "Email ID",
  password: "Password",
  name: "Name",
  OTPVerification: "OTP verification",
  login: "Login",
  addressFiledName: "Address Filed Name",
  couponCode: "Coupon Code",
  fnpCashCoupon: "Fnp Cash/Coupon",
  UPICollect: "UPI collect",
  CCDC: "CC/DC",
  all: "All",
  wallet: "Wallet",
  netBanking: "Netbanking",
  MICROSITE: "MICROSITE",
  ACCOUNT: "ACCOUNT",
  PRODUCT: "PRODUCT",
  HOME: "HOME",
  PLP: "PLP",
  PAGE_NOT_FOUND: "PAGE NOT FOUND",
  SOMETHING_WENT_WRONG: "SOMETHING WENT WRONG",
};
