import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { parseCookies } from "nookies";
import { makeStyles } from "@material-ui/styles";
import APP_CONSTANTS from "../../src/action-constants/app-actions";
import DHeader from "./desktop/desktop-header";
import DFooter from "./desktop/desktop-footer";
import { getRoutePageName, RoutePageConstants } from "../../src/utils/common";
import styles from "./styles/desktop-layout.module.scss";
import errorLogger from "../../app_configs/logger-service";
import { CDP_ERORR_MSG } from "../../src/constants/common/errorConstants";
import CommonPageActionsConstants from "../../src/action-constants/common-page-actions-constants";
import callClevertapEvent from "../../src/analytics/clevertapUtility";
import { cleverTapEvents, cleverTapEventsConstants } from "../../src/analytics/clevertapConstants";

const Error = dynamic(() => import("../../pages/_error"), {
  ssr: false,
});

const useStyles = makeStyles({
  backdropDesktopMainContainer: {
    position: "absolute",
    minHeight: "100vh",
    inset: "0px",
    zIndex: "999",
    background: "rgba(0, 0, 0, 0.8)",
    transition: "all 0.3s ease 0s",
    opacity: "1",
  },
});

/**
 * Component for layout of Desktop
 *
 * @param {object} root0  props passed to the component .
 * @param {React.ReactElement} root0.children represents children passed to DesktoLayout
 * @param {number} root0.statusCode represents statusCode pass to the layout
 * @param {string} root0.errorPageName represents error page name
 * @returns {React.ReactElement} main JSX for the DesktopLayout component
 */
function DesktopLayout({ children, statusCode, errorPageName }) {
  const { rootGeoId } = useSelector((state) => state.appConfigs);
  const showBackDrop = useSelector((state) => state.cartDetails.showBackDrop);
  const classes = useStyles();
  const router = useRouter();
  const dispatch = useDispatch();
  const { lastSelectedPincode, faul, lastSelectedAddress } = parseCookies();
  const pageName = errorPageName || getRoutePageName(router.asPath.startsWith("/search") ? "/search" : router.pathname);
  const slug1 = router.query?.slug1;
  const countTime = useRef(0);

  useEffect(() => {
    /**
     * This method invokes initial method of cdp events mapper and all dom bindings.
     */
    const initializeCdp = async () => {
      const module = await import("../../src/cdp/cdp-events-mapper");
      module.initCdpEvents(pageName, "pageLoad", "");
      module.initDomBindings();
    };
    /**
     * This method waits for cdp object to be present on the dom to call
     * the callback function to initialize cdp events.
     */
    function waitForCdpObject() {
      countTime.current += 1;
      if (window.cdp) {
        initializeCdp();
      } else if (countTime.current <= 2) {
        setTimeout(() => {
          waitForCdpObject();
        }, 500);
      } else {
        errorLogger(CDP_ERORR_MSG);
      }
    }

    waitForCdpObject();
  }, [router.query.slug1, pageName]);

  useEffect(() => {
    if (window.localStorage.getItem("socialLogin") && lastSelectedPincode && lastSelectedAddress && faul) {
      dispatch({
        type: APP_CONSTANTS.SET_LOGGED_IN_PINCODE,
        payload: {
          pincode: lastSelectedPincode,
          email: faul,
          customerAddress: lastSelectedAddress,
        },
      });

      window.localStorage.removeItem("socialLogin");
    }
    if (window.sessionStorage.getItem("ctapSocialLogin")) {
      const loginAttributes = {
        Site: {
          Identity: faul,
          Email: faul,
        },
      };
      const loginSuccessEvent = {
        loginSuccess: "Login Success",
      };
      const publishCleverTapData = {
        profile: { identity: faul, platform: "web" },
      };
      dispatch({ type: CommonPageActionsConstants.POST_DATA_IN_CLEVER_TAP, payload: publishCleverTapData });
      callClevertapEvent(cleverTapEvents.userLogin, loginAttributes, cleverTapEvents.userLogin);
      callClevertapEvent(cleverTapEventsConstants.loginSuccess, loginSuccessEvent);
      window.sessionStorage.removeItem("ctapSocialLogin");
    }
    // not required re-rendering only it executes on first render of page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({ type: CommonPageActionsConstants.GET_CALL_CHAT_TIME });
  }, [dispatch]);

  return (
    <>
      <header id="header" className={styles["header-strip"]}>
        <DHeader country={rootGeoId} pageName={pageName} pageSlug={slug1} />
      </header>
      <main id="main" className={styles["desktop-main"]}>
        <div id="backdrop_wrapper" className={showBackDrop ? classes.backdropDesktopMainContainer : ""} />
        {pageName !== RoutePageConstants.SERVER_ERROR ? (
          children
        ) : (
          <Error statusCode={statusCode} pageName={pageName} />
        )}
      </main>
      <DFooter pageName={pageName} pageSlug={slug1} />
    </>
  );
}

DesktopLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  statusCode: PropTypes.number,
  errorPageName: PropTypes.string,
};

DesktopLayout.defaultProps = {
  children: React.createElement("span"),
  statusCode: 200,
  errorPageName: "",
};

export default DesktopLayout;
