import { parseCookies, setCookie } from "nookies";
import { getClevertapInstance } from "../../components/hooks/analytics/clevertap/useClevertapSetup";
import { cleverTapEvents, cleverTapEventsConstants } from "./clevertapConstants";
import {
  getBrowserName,
  getOperatingSystem,
  isMobileDevice,
  parseQueryString,
  removeEmptyNullAndUndefinedFromObject,
} from "../utils/common";

/**
 * This is a utility method to call cleverTap events
 *
 * @param {string} eventName - contains event name
 * @param {object} eventData - contains object to be shared in event
 * @param {string} eventType - contains event type
 */
function callClevertapEvent(eventName, eventData, eventType = cleverTapEvents.common) {
  const { cleverTapWebSession } = parseCookies();
  const isMobile = isMobileDevice();
  const operatingSystem = getOperatingSystem();
  const browserName = getBrowserName();
  const cleverTapInstance = getClevertapInstance();
  const filteredEventData = removeEmptyNullAndUndefinedFromObject(eventData);
  const cookieTTL = 20 * 60;

  setCookie(null, "cleverTapWebSession", "webSessionStarted", {
    maxAge: cookieTTL,
    path: "/",
  });

  const searchQuery = parseQueryString(window.location.search);
  const { utm_source: utmSource, utm_medium: utmMedium, utm_campaign: utmCampaign, gclid } = searchQuery;

  if (!cleverTapWebSession) {
    const payload = {
      Platform: isMobile ? "mobile" : "desktop",
      browser: browserName,
      page_name: eventData?.page_name,
      page_type: eventData?.page_type,
      complete_url: window.location.href,
      clean_url: `${window.location.origin}${window.location.pathname}`,
      operating_system: operatingSystem,
      utm_source_name: utmSource,
      utm_medium_name: utmMedium,
      utm_campaign_name: utmCampaign,
      gclid,
    };

    callClevertapEvent(cleverTapEventsConstants.sessionStart, payload);
  }

  if (cleverTapInstance) {
    try {
      switch (eventType) {
        case cleverTapEvents.common:
          cleverTapInstance.event.push(eventName, filteredEventData);
          break;
        case cleverTapEvents.userLogin:
          cleverTapInstance.onUserLogin.push(filteredEventData);
          break;
        default:
          cleverTapInstance.event.push(eventName, filteredEventData);
          break;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("CleverTap event push failed:", error);
    }
  } else {
    // eslint-disable-next-line no-console
    console.warn("CleverTap instance is not initialized.");
  }
}

export default callClevertapEvent;
